<template>
  <div>
    <b-overlay :show="loading">
      <b-card v-if="paketAktif[0] != null">
        <b-card-title><h4>Paket Aktif</h4></b-card-title>
        <b-card-body>
          <swiper
            class="swiper-responsive-breakpoints"
            :options="swiperOptions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          >
            <swiper-slide v-for="(item, i) in paketAktif" :key="i">
              <b-card class="border">
                <b-media vertical-align="top" class="mb-2">
                  <template #aside>
                    <b-img
                      :src="apiFile + item.thumbnail"
                      blank-color="#ccc"
                      width="100"
                      alt="placeholder"
                    />
                  </template>
                  <small>7 Subtes</small>
                  <h5 class="media-heading mt-1">
                    <b>{{ item.name }}</b>
                  </h5>
                </b-media>
                <small class="font-weight-bold">
                  Aktif hingga {{ humanDate(item.info_end) }}
                </small>
              </b-card>
            </swiper-slide>
          </swiper>
        </b-card-body>
      </b-card>
      <b-card>
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <h3>History</h3>
          </div>
        </div>
      </b-card>
      <b-card>
        <b-tabs fill pills content-class="pt-1">
          <b-tab title="Semua">
            <All :data="data" @load="(value) => setFIlterType(value)" />
          </b-tab>

          <b-tab title="Sudah Bayar">
            <Terbayar
              :data="dataTerbayar"
              @load="(value) => setFIlterType(value)"
              v-if="dataTerbayar[0] != null"
            />
            <b-card class="text-center" v-else>
              <b-card-text>
                Tidak ada billing dengan status "Sudah Bayar"
              </b-card-text>
            </b-card>
          </b-tab>
          <b-tab title="Menunggu">
            <Menunggu
              :data="dataMenunggu"
              @load="(value) => setFIlterType(value)"
              v-if="dataMenunggu[0] != null"
            />
            <b-card class="text-center" v-else>
              <b-card-text>
                Tidak ada billing dengan status "Menunggu"
              </b-card-text>
            </b-card>
          </b-tab>
          <b-tab title="Dibatalkan">
            <Dibatalkan
              :data="dataDibatalkan"
              v-if="dataDibatalkan[0] != null"
            />
            <b-card class="text-center" v-else>
              <b-card-text>
                Tidak ada billing dengan status "Dibatalkan"
              </b-card-text>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BOverlay,
  BCardTitle,
  BCard,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BTable,
  BTabs,
  BTab,
  BButton,
  BMedia,
  BImg,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import All from './components/All.vue'
import Terbayar from './components/Terbayar.vue'
import Menunggu from './components/Menunggu.vue'
import Dibatalkan from './components/Dibatalkan.vue'
import TelatBayar from './components/TelatBayar.vue'
import Dikembalikan from './components/Dikembalikan.vue'
export default {
  data() {
    return {
      data: [],
      dataTerbayar: [],
      dataMenunggu: [],
      dataDibatalkan: [],
      dataTelatBayar: [],
      dataDikembalikan: [],
      paketAktif: [],
      loading: false,
      filterType: null,
      swiperOptions: {
        slidesPerView: 5,
        spaceBetween: 50,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    AppCollapse,
    AppCollapseItem,
    BOverlay,
    BCardTitle,
    BCard,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BTable,
    BTabs,
    BTab,
    BButton,
    BMedia,
    BImg,
    BBadge,
    All,
    Terbayar,
    Menunggu,
    TelatBayar,
    Dibatalkan,
    Dikembalikan,
  },
  directives: {
    Ripple,
  },
  computed: {
    apiFile() {
      return 'http://file.elp-dev.my.id/'
    },
  },
  methods: {
    setFIlterType(value) {
      this.filterType = value
      this.getData()
    },
    getData() {
      this.loading = true
      const params = {
        order: 'desc',
        user_id: this.user.id,
        type: this.filterType,
      }
      this.$store
        .dispatch('transaksi/index', params)
        .then((response) => {
          this.loading = false
          this.data = response.data.data
          this.dataTerbayar = this.data.filter((res) => {
            return res.status == 'PAID'
          })
          this.dataMenunggu = this.data.filter((res) => {
            return res.status == 'UNPAID'
          })
          this.dataDibatalkan = this.data.filter((res) => {
            return (
              res.status == 'CANCELED' ||
              res.status == 'FAILED' ||
              res.status == 'EXPIRED' ||
              res.status == 'REFUND'
            )
          })
          this.dataTelatBayar = this.data.filter((res) => {
            return res.status == 'EXPIRED'
          })
          this.dataDikembalikan = this.data.filter((res) => {
            return res.status == 'REFUND'
          })
        })
        .catch((error) => {
          this.loading = false
          this.$root.$emit('errorData', error)
        })
        .finally(() => {
          this.selected = []
        })
    },
    getDataTryoutAktif() {
      this.loading = true
      let params = {
        active: 1,
      }
      this.$store
        .dispatch('tryout/myPaket', params)
        .then((res) => {
          this.loading = false
          this.paketAktif = res.data.data
        })
        .catch((err) => {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Error `,
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message,
            },
          })
        })
    },
  },
  created() {
    this.getData()
    this.getDataTryoutAktif()
  },
}
</script>

<style>
li.mt-1 {
  border-bottom: 3px solid #e83b07;
  padding-bottom: 10px;
  list-style: none;
}
</style>
